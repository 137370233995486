import { DownloadOutlined } from '@ant-design/icons';
import { Badge, Button, Dropdown, List } from 'antd';
import withToasts from 'src/components/MessageToasts/withToasts';
import Icons from 'src/components/Icons';
import React, { useCallback, useEffect, useState } from 'react';
import { css, SupersetClient } from '@superset-ui/core';
import { Global } from '@emotion/react';
import { transparentize } from 'polished';
import { theme } from '../../preamble';

const badgeCss = css`
  @keyframes jump {
    0% {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
    30% {
      transform: translate3d(-10%, 20%, 0) scale3d(0.9, 1.1, 1);
    }
    70% {
      transform: translate3d(0, -20%, 0) scale3d(1, 0.8, 1);
    }
    85% {
      transform: translate3d(0, 20%, 0) scale3d(1, 1, 1);
    }
    100% {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }
  }

  margin-right: 30px;

  .ant-badge-count {
    top: -7px;
    right: -10px;
    transform: unset;
  }

  &.jump .ant-badge-count {
    animation: jump 1s ease-in-out alternate;
  }
`;

const styles = css`
  .exports-dropdown-overlay {
    top: 42px !important;

    .ant-dropdown-arrow {
      box-shadow: -1px -1px 0.5px ${transparentize(0.7, theme.colors.grayscale.dark1)};
    }

    .ant-dropdown-menu {
      box-shadow: 0 0 2px ${transparentize(0.5, theme.colors.grayscale.dark1)},
        0 3px 6px -4px ${transparentize(0.88, theme.colors.grayscale.dark1)},
        0 6px 16px 0 ${transparentize(0.92, theme.colors.grayscale.dark1)},
        0 9px 28px 8px ${transparentize(0.95, theme.colors.grayscale.dark1)};
    }

    .ant-dropdown-menu-items {
      padding: 0px 15px;
      margin: 0px;
    }

    .ant-list-item-meta {
      align-items: center;

      &.done-exported-item {
        .ant-list-item-meta-avatar,
        .ant-list-item-meta-title {
          cursor: pointer;
        }
      }
      &:not(.done-exported-item) {
        .ant-list-item-meta-avatar,
        .ant-list-item-meta-title {
          cursor: unset;
        }
      }
      &.failed-exported-item {
        .ant-list-item-meta-avatar,
        .ant-list-item-meta-title {
          color: ${theme.colors.error.base};
        }
      }
    }

    .ant-list-item-meta-avatar > * {
      vertical-align: middle;
      cursor: unset;
      fill: ${theme.colors.grayscale.dark1};
    }

    .ant-list-item-meta-content {
      width: auto;

      .ant-list-item-meta-title {
        margin: 0;
        max-width: 350px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 1.1;
      }
    }

    .ant-list-item-action {
      & > li:last-child {
        padding-right: 0;
      }

      .anticon {
        color: ${theme.colors.grayscale.dark1};
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }
`;

export const REFRESH_EXPORT_LIST = 'refresh_export_list';

interface LargeReportsItem {
  id: string;
  slice_name: string;
  status: 'done' | 'in_progress' | 'failed';
}

function LargeReports({ ...props }: any) {
  const [items, setItems] = useState<LargeReportsItem[]>([]);
  const [isOpened, setIsOpened] = useState<boolean>();
  const [isJumping, setJumping] = useState<boolean>();
  const makeJump = () => {
    if (isJumping || isOpened) {
      return;
    }
    setJumping(true);
    setTimeout(() => setJumping(false), 3000);
  };
  // fields
  // const { current: self } = useRef({
  //   isSending: false,
  // });
  const updateList = useCallback(({ data: jobs }) => {
    const newItems = JSON.parse(jobs) as LargeReportsItem[];
    setItems(prevItems => {
      if (
        newItems
          .filter(({ status }) => status === 'done')
          .find(
            ({ id }) =>
              !prevItems.find(item => item.id === id) ||
              prevItems.find(item => item.id === id && item.status !== 'done'),
          )
      ) {
        // an one item is ready
        makeJump();
      }
      return newItems;
    });
  }, []);

  useEffect(() => {
    // init
    const channel = new EventSource('/api/v1/chart/big_data/stream');
    channel.addEventListener('message', updateList);

    // 👇️ cancel interval when the component unmounts
    return () => {
      channel.removeEventListener('message', updateList);
    };
  }, []);

  const onTitleClick = (e: React.MouseEvent<HTMLElement>, job_id: string) => {
    e.stopPropagation();
    window.open(
      `${window.location.origin}/api/v1/chart/big_data/${job_id}`,
      '_self',
    );
  };
  const onDelete = (e: React.MouseEvent<HTMLElement>, job_id: string) => {
    e.stopPropagation();
    SupersetClient.delete({
      endpoint: `api/v1/chart/big_data/${job_id}`,
    });
  };

  return items.length ? (
    <>
      <Global styles={styles} />
      <Badge
        {...props}
        count={items.length}
        css={badgeCss}
        className={isJumping ? 'jump' : undefined}
      >
        <Dropdown
          trigger={['click']}
          arrow
          placement="bottomCenter"
          onVisibleChange={visible => setIsOpened(visible)}
          overlay={
            <List
              itemLayout="horizontal"
              dataSource={items}
              renderItem={item => (
                <List.Item
                  actions={[
                    <Icons.DeleteOutlined
                      iconSize="m"
                      key="delete"
                      onClickCapture={e => onDelete(e, item.id)}
                    />,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      item.status === 'in_progress' ? (
                        <Icons.LoadingOutlined iconSize="s" />
                      ) : (
                        <Icons.FileOutlined
                          iconSize="s"
                          onClickCapture={
                            item.status === 'done'
                              ? e => onTitleClick(e, item.id)
                              : undefined
                          }
                        />
                      )
                    }
                    title={
                      <span
                        onClickCapture={
                          item.status === 'done'
                            ? e => onTitleClick(e, item.id)
                            : undefined
                        }
                      >
                        {item.slice_name}
                      </span>
                    }
                    className={`${item.status}-exported-item`}
                  />
                </List.Item>
              )}
            />
          }
          overlayClassName="exports-dropdown-overlay"
        >
          <Button shape="round" size="middle" icon={<DownloadOutlined />}>
            Exports
          </Button>
        </Dropdown>
      </Badge>
    </>
  ) : (
    <></>
  );
}

export default withToasts(LargeReports);
